@import './variables';

.df-row {
	display: flex;
	flex-direction: row;
}

.df-row-ac {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.df-row-ab {
	display: flex;
	flex-direction: row;
	align-items: baseline;
}

.df-row-afs {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
}

.df-col-ac {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.df-col-ab {
	display: flex;
	flex-direction: column;
	align-items: baseline;
}

.df-col {
	display: flex;
	flex-direction: column;
}

.jc-sb {
	justify-content: space-between;
}

.jc-c {
	justify-content: center;
}

.jc-e {
	justify-content: flex-end;
}

.jc-se {
	justify-content: space-evenly;
}

.sticky-at-top {
	position: sticky;
	top: 0;
}

.stretch-ver {
	height: 100%;
}

.stretch-hor {
	width: 100%;
}

.tac {
	text-align: center;
}

.fg1 {
	flex-grow: 1;
}

.font-bold {
	font-weight: 600;
}

.font-light {
	color: lightgray;
}

.font-error {
	color: $error-color;
}

.pos-rel {
	position: relative;
}

.mr-5px {
	margin-right: 5px;
}
