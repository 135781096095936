@import './styles/variables';

.outdated-application-header {
	background: $neutral-4-color;
	padding: 10px;
	border-bottom: 1px solid #9e9e9e;
	.app-update-button {
		margin-left: 10px;
	}
}

.empty-page {
	background: $secondary-2-color;
	width: 100%;
	height: 100%;
}
