@import '../../../styles/variables';

.text-link-button{
    padding: 0px;
    background: transparent;
    border: none;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.25s ease-in-out;
    &:hover{
        opacity: 0.8;
    }
    &.tlb-gray{
        color: $neutral-3-color;
    }
    &.tlb-underline{
        text-decoration: underline;
    }
    font-size: 16px;
    line-height: 25px;
    &.tlb-small{
        font-size: 14px;
        line-height: 21px;
    }
    &.tlb-extra-small{
        font-size: 12px;
        line-height: 16px;
    }
    &:focus{
        outline: none;
    }
}