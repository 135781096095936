@import '../../../styles/variables';

.dfi-container{
    .dfi-selected-value{
        padding: 10px;
        text-align: center;
    }
    .dfi-enum-options{
        padding: 5px;
        border-right: 1px solid $neutral-4-color;
    }
    table{
        .suicr-dates-range-view-header{
            tr:first-child{
                display: none;
            }
            tr:nth-child(2){
                th{
                    color: rgb(123, 123, 123);
                    padding-top: 8px;
                    padding-bottom: 0px;
                    padding-left: 0px;
                    padding-right: 0px;
                }
            }
            tr:last-child{
                th{
                    font-size: 11px;
                    color: rgb(123, 123, 123);
                    padding-top: 8px;
                    padding-bottom: 8px;
                    padding-left: 0px;
                    padding-right: 0px;
                }
            }
        }
        tbody{
            td{
                padding-top: 7px !important;
                padding-bottom: 7px !important;
            }
        }
    }
    .ui.table td.active{
        background-color: #4bc800!important;
        color: white !important;
    }
}

