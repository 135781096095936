@import '../styles/variables';

// sticky implecations.
.container{
    height: 100%;
    .body-wrapper{
        overflow: auto;
        width: 100%;
    }
}

.container{
    .side-nav{
        background-color: $secondary-2-color;
        padding: 20px 10px 20px 15px;
        .c-label{
            margin-left: 5px;
            font-size: 10px;
        }
        .nav-links{
            margin-top: 6px;
            a{
                cursor: pointer;
                color: $neutral-2-color;
                text-decoration: none;
                &.active{
                    font-weight: 600;
                    color: $primary-color;
                }
            }
            a.sub-nav-link{
                font-size: 16px;
                margin-top: 10px;
            }
            a.upper-nav-link{
                margin-top: 25px;
                font-size: 16px;
            }
            a.lower-nav-link{
                font-size: 14px;
                margin-top: 15px;
            }
            .company-name{
                font-weight: 600;
                font-size: 14px;
            }
            .sub-nav-link{
                margin-left: 10px;   
            }
        }
    }
    .body-wrapper{
        background: $secondary-2-color;
        .top-bar{
            background: $secondary-2-color;
            padding: 20px 20px 5px 20px;
            z-index: $z-index-dashboard-top-bar;
            .search-anything{
                flex-grow: 1;
                margin-right: 20px;
                max-width: 450px;
            }
            .user-text{
                font-size: 14px;
                line-height: 21px;
                margin-left: 15px;
            }
        }
        .body-content{
            padding: 20px 20px 20px 20px;
            height: 100%;
        }
    }
}