@import '../../styles/variables';

.history-container{
    .label{
        color: $neutral-3-color;
        font-size: 12px;
        margin-top: 8px;
    }
    .info{
        font-size: 14px;
    }
    .title{
        font-weight: 600;
        margin-bottom: 10px;
    }
    .search-history{
        margin-left: -10px;
    }
}