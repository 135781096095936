@import '../../../styles/variables';

.field-group{
    display: flex;
    flex-direction: column;
    &:not(.no-margin){
        margin-bottom: 20px;
    }
    label{
        line-height: 18px;
        font-size: 16px;
    }
    input::placeholder{
        font-size: 14px;
    }
    input, select{
        box-sizing: border-box;	
        border: $default-border;
        border-radius: 2px;
        font-size: 18px;
        line-height: 18px;
        padding: 11.5px 8.5px;
        color: $neutral-1-color;
    }
    // select{
    //     option{
    //         &:hover{
    //             // background-color: black;
    //         }
    //     }
    // }
    .error-message{
        font-size: 14px;
        color: $error-color;   
        padding-top: 5px;
        // animation: slide-up 0.25s ease-in-out;
    }
    .label-container{
        margin-bottom: 3px;
        .optional-tag{
            color: $neutral-3-color;
            font-size: 14px;
            // line-height: 21px;
        }
    }
}

// @keyframes slide-up {
//     0% {
//         opacity: 0;
//         transform: translateY(20px);
//     }
//     100% {
//         opacity: 1;
//         transform: translateY(0);
//     }
// }
