@import '../../styles/variables';

.full-page-sheet{
    background: $secondary-2-color;
    width: 100%;
    height: 100%;
    .login-header{
        padding: 20px 40px;
    }
    .login-body{
        margin-top: 30px;
        flex-grow: 1;
        .welcome-text{
            font-size: 30px;
            font-weight: bold;
            line-height: 46px;
        }
        .sub-welcome-text{
            font-size: 16px;
            line-height: 25px;
            margin-bottom: 40px;
        }
        .form-dialog{
            width: 600px;
        }
        // .primary-button{
        //     margin-top: 20px;
        // }
        .forgot-password-button{
            margin-top: 4px;
            margin-bottom: 20px;
        }
    }
}