.base-table{
    .bt-footer{
        .bt-footer-page-info{
            color: #777777;
            font-size: 14px;
            line-height: 21px;
        }
        .bt-previous-button{
            margin-right: 10px;
        }
    }
    td.can-select{
        cursor: pointer;
    }
}