.orders-card{
    padding: 0px !important;
    .orders-header{
        padding: 15px;
        .title{
            font-size: 18px;
            font-weight: 600;
        }
    }
    .edit-button{
        margin-left: 8px;
    }
}