@import '../../../styles/variables';

.dd-custom{
    .search-box-container{
        background: $white;
    }
    .dd-search-box{
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .dd-trigger{
        cursor: pointer;
        background-color: $white;
        border-radius: 2px;  
        &:not(.is-open){
            border: $light-border;
        }
        &.is-open {
            border-top: $light-border;
            border-left: $light-border;
            border-right: $light-border;
        }
        input{
            padding: 10px;
            border: none;
            flex-grow: 1;
            padding: 11.5px 8.5px;
            font-size: 18px;
            line-height: 29px;
            &:focus{
                outline: none;
            }
        }
        .label-text{
            flex-grow: 1;
            color: $neutral-3-color;
        }
        .label-text:not(.small){
            padding: 11.5px 8.5px;
            font-size: 18px;
            line-height: 29px;
        }
        .label-text.small{
            padding: 10px;
        }
        .ddt-icon{
            color: $neutral-3-color;
            padding: 10px 10px 10px 0px;
        }        
        &.selected:not(.format-selected){
            .label-text{
                color: $neutral-1-color;
            }
        }
        &.format-selected{
            background-color: $primary-color;
            .label-text{
                color: $white;
            }
            .ddt-icon{
                color: $white;
            }
        }
    }
    .menu.transition.visible{
        border-left: $light-border;
        border-right: $light-border;
        border-bottom: $light-border;
        border-top: none;
        border-radius: 0px;
        box-shadow: none;
    }
}


.simple-list-item{
    font-size: 16px;
    line-height: 18px;
    padding: 8px 10px 8px 10px;
    color: $neutral-1-color;
    cursor: pointer;
    &:hover{
        background: rgba(177, 177, 177, 0.133);
    }
    &.selected{
        color: $white;
        background: $primary-color;
    }

}
