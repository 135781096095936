@import '../styles/variables';

.dashboard-search-box{
    border: $light-border;
    background-color: $white;
    box-shadow: 2px 2px 8px 0 $neutral-4-color;
    border-radius: 2px;
    padding: 10.5px 14.5px;
    color: $neutral-3-color;
    font-size: 12px;
    line-height: 12px;
}