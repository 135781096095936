@import '../../styles/variables';

.transport-detail-card{
    .label{
        color: $neutral-3-color;
        font-size: 14px;
        line-height: 21px;
        &:not(.without-margin){
            margin-top: 20px;
        }
    }
    .info{
        font-size: 16px;
        line-height: 25px;
    }
    .single-price-calc{
        margin-right: 20px;
    }
    // .export-button{
    //     margin-left: 20px;
    //     padding-left: 20px;
    //     padding-right: 20px;
    // }
    .dispute-button, .download-button{
        margin-left: 10px;
    }

    .status{
        padding: 7px 15px 7px 15px;
        border-radius: 100px;
        &.confirmed{
            color: white;
            background-color: $success-color;
        }
        &:not(.confirmed){
            background-color: $neutral-4-color;
        }
        .status-text{
            margin-left: 5px;
            line-height: 21px;
            white-space: nowrap;
        }
    }
}