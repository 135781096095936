@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,700);
.ui.card {
  padding: 15px;
  margin: 0;
  width: auto;
  box-shadow: 2px 2px 8px 0 rgba(151, 151, 151, 0.6);
  background-color: #ffffff;
  border-radius: 2px;
  border: none;
}

.ui.modal {
  position: relative;
}
.ui.modal .content {
  height: 100%;
  padding: 28px 50px 31px 50px;
}

.ui.table {
  margin-top: 0;
  border: none;
  border-radius: 0;
}

.ui.table tfoot th {
  background: white;
}

.ui.table td.no-padding {
  padding: 0px;
}

.ui.divider.no-margin {
  margin: 0px;
}

.ui.table thead th {
  font-weight: 500;
  background: #EDEDED !important;
  border-left: none !important;
}
.ui.table thead th:hover {
  background: #EDEDED;
}
.ui.table thead th.no-sort {
  cursor: default;
}

.ui.scrolling.dropdown .menu.dd-menu {
  min-width: 250px !important;
}
.ui.scrolling.dropdown .menu.no-max-height {
  max-height: none !important;
}

.Toastify__toast {
  font-family: "Poppins", sans-serif !important;
  box-shadow: 2px 2px 8px 0 #9e9e9e !important;
  background-color: #9e9e9e !important;
  border-radius: 5px !important;
  padding: 28px 20px !important;
}

.Toastify__toast--success {
  background-color: #4EB064 !important;
}

.Toastify__toast--warning {
  background-color: #F6B93B !important;
}

.Toastify__toast--error {
  background-color: #EB2F06 !important;
}

.df-row {
  display: flex;
  flex-direction: row;
}

.df-row-ac {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.df-row-ab {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.df-row-afs {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.df-col-ac {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.df-col-ab {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.df-col {
  display: flex;
  flex-direction: column;
}

.jc-sb {
  justify-content: space-between;
}

.jc-c {
  justify-content: center;
}

.jc-e {
  justify-content: flex-end;
}

.jc-se {
  justify-content: space-evenly;
}

.sticky-at-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.stretch-ver {
  height: 100%;
}

.stretch-hor {
  width: 100%;
}

.tac {
  text-align: center;
}

.fg1 {
  flex-grow: 1;
}

.font-bold {
  font-weight: 600;
}

.font-light {
  color: lightgray;
}

.font-error {
  color: #EB2F06;
}

.pos-rel {
  position: relative;
}

.mr-5px {
  margin-right: 5px;
}

html {
  display: flex;
  width: 100%;
}
html body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

html,
body,
#root {
  height: 100%;
  overflow: auto;
}

input,
textarea,
select,
button {
  font-family: "Poppins", sans-serif;
}
.toast-notification {
  font-family: "Poppins", sans-serif;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
}
.primary-button {
  border-radius: 4px;
  box-shadow: 2px 2px 4px 0 rgba(158, 158, 158, 0.5);
  text-align: center;
  text-decoration: none;
  border: 1px solid #DE192B;
  cursor: pointer;
  transition: opacity 0.25s ease-in-out;
  white-space: nowrap;
}
.primary-button .loader-wrapper {
  position: absolute;
  width: 100%;
}
.primary-button .loader-children {
  visibility: hidden;
}
.primary-button.stretch {
  width: 100%;
}
.primary-button:not(.small) {
  font-size: 20px;
  line-height: 30px;
  padding: 15px;
  font-weight: 600;
}
@media screen and (max-width: 767px) {
  .primary-button:not(.small) {
    font-size: 16px;
    line-height: 25px;
  }
}
.primary-button.small {
  font-size: 14px;
  line-height: 21px;
  padding: 5px;
  font-weight: 400;
}
.primary-button:not(.outline) {
  background-color: #DE192B;
  color: #ffffff;
}
.primary-button.outline {
  color: #2E2D2E;
  border: 1px solid #DFDFDF;
  background: transparent;
}
.primary-button:hover:not(.drop-down disabled) {
  opacity: 0.7;
}
.primary-button:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}
.primary-button:focus {
  outline: none;
}
.primary-button.rectangle-padding {
  padding: 10px 30px;
}
.text-link-button {
  padding: 0px;
  background: transparent;
  border: none;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.25s ease-in-out;
  font-size: 16px;
  line-height: 25px;
}
.text-link-button:hover {
  opacity: 0.8;
}
.text-link-button.tlb-gray {
  color: #9e9e9e;
}
.text-link-button.tlb-underline {
  text-decoration: underline;
}
.text-link-button.tlb-small {
  font-size: 14px;
  line-height: 21px;
}
.text-link-button.tlb-extra-small {
  font-size: 12px;
  line-height: 16px;
}
.text-link-button:focus {
  outline: none;
}
.form-dialog {
  display: flex;
  flex-direction: column;
  padding: 40.5px 70px;
  box-sizing: border-box;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 2px 2px 8px 0 #DFDFDF;
  max-width: 600px;
}
.icon-button {
  padding: 0px;
  background: transparent;
  border: none;
}
.icon-button:disabled {
  cursor: not-allowed;
}
.icon-button:not(:disabled) {
  cursor: pointer;
}
.icon-button:focus {
  outline: none;
}
.icon-button.gray {
  color: #DFDFDF;
}
.icon-button.large {
  font-size: 18px;
}
.icon-button.very-large {
  font-size: 24px;
}
.icon-button.success {
  color: #4EB064;
}
.icon-button.warning {
  color: #F6B93B;
}
.icon-button.error {
  color: #EB2F06;
}
.field-group {
  display: flex;
  flex-direction: column;
}
.field-group:not(.no-margin) {
  margin-bottom: 20px;
}
.field-group label {
  line-height: 18px;
  font-size: 16px;
}
.field-group input::-webkit-input-placeholder {
  font-size: 14px;
}
.field-group input::placeholder {
  font-size: 14px;
}
.field-group input, .field-group select {
  box-sizing: border-box;
  border: 1px solid #DFDFDF;
  border-radius: 2px;
  font-size: 18px;
  line-height: 18px;
  padding: 11.5px 8.5px;
  color: #2E2D2E;
}
.field-group .error-message {
  font-size: 14px;
  color: #EB2F06;
  padding-top: 5px;
}
.field-group .label-container {
  margin-bottom: 3px;
}
.field-group .label-container .optional-tag {
  color: #9e9e9e;
  font-size: 14px;
}
.password-input-field input {
  width: 100%;
}
.password-input-field .icon-button {
  position: relative;
  margin-right: 13px;
  margin-left: auto;
  float: right;
  margin-top: -34px;
  color: gray;
}
.full-page-sheet {
  background: #EFF5FB;
  width: 100%;
  height: 100%;
}
.full-page-sheet .login-header {
  padding: 20px 40px;
}
.full-page-sheet .login-body {
  margin-top: 30px;
  flex-grow: 1;
}
.full-page-sheet .login-body .welcome-text {
  font-size: 30px;
  font-weight: bold;
  line-height: 46px;
}
.full-page-sheet .login-body .sub-welcome-text {
  font-size: 16px;
  line-height: 25px;
  margin-bottom: 40px;
}
.full-page-sheet .login-body .form-dialog {
  width: 600px;
}
.full-page-sheet .login-body .forgot-password-button {
  margin-top: 4px;
  margin-bottom: 20px;
}
.outdated-application-header {
  background: #DFDFDF;
  padding: 10px;
  border-bottom: 1px solid #9e9e9e;
}
.outdated-application-header .app-update-button {
  margin-left: 10px;
}

.empty-page {
  background: #EFF5FB;
  width: 100%;
  height: 100%;
}
.default-modal {
  max-width: 600px;
  min-height: 400px;
}
.full-screen-loader {
  position: absolute;
  z-index: 2;
  background-color: rgba(239, 245, 251, 0.85);
}
.base-table .bt-footer .bt-footer-page-info {
  color: #777777;
  font-size: 14px;
  line-height: 21px;
}
.base-table .bt-footer .bt-previous-button {
  margin-right: 10px;
}
.base-table td.can-select {
  cursor: pointer;
}

.confirmation-modal .confirmation-modal-inner {
  flex-grow: 1;
}
.confirmation-modal .mc-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 31px;
}
@media screen and (max-width: 767px) {
  .confirmation-modal .mc-title {
    font-size: 16px;
    line-height: 20px;
  }
}
.confirmation-modal .mc-description {
  flex-grow: 1;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 26px;
}
@media screen and (max-width: 767px) {
  .confirmation-modal .mc-description {
    font-size: 14px;
    line-height: 22px;
  }
}
.confirmation-modal .cancel-button {
  margin-right: 20px;
  white-space: nowrap;
}
@media screen and (max-width: 767px) {
  .confirmation-modal .cancel-button {
    margin-right: inherit;
    margin-top: 15px;
    margin-bottom: 10px;
  }
}
.disputes-card {
  padding: 0px !important;
}
.disputes-card .disputes-header {
  padding: 15px;
}
.disputes-card .disputes-header .title {
  font-size: 18px;
  font-weight: 600;
}
.disputes-card .edit-button {
  margin-left: 8px;
}
.filter-bar {
  padding-left: 15px;
  height: 50px;
}
.filter-bar .filter-on-text {
  margin-right: 12px;
}
.filter-bar .filter-clear-button {
  margin-left: 20px;
  color: #DFDFDF;
}
.filter-trigger {
  padding: 6px;
  margin-right: 6px;
}
.filter-trigger:hover {
  background: rgba(177, 177, 177, 0.133);
  border-radius: 8px;
}
.filter-trigger .ft-icon {
  background: #DFDFDF;
  margin-right: 7px;
  color: white;
  border-radius: 4px;
  height: 18px;
  width: 18px;
  font-size: 10px;
}
.filter-trigger .ft-icon.ft-icon-selected {
  background: #4EB064;
}
.filter-trigger .ft-label {
  position: absolute;
  background: #EB2F06;
  text-align: center;
  width: 16px;
  height: 16px;
  font-size: 11px;
  border-radius: 100px;
  left: 0;
  top: 0;
  line-height: 16px;
}

.dd-menu {
  min-width: 250px !important;
  max-height: none;
}
.custom-list-item {
  font-size: 14px;
  cursor: pointer;
  height: 30px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 1px;
  margin-bottom: 1px;
  border-radius: 8px;
}
.custom-list-item:hover {
  background: rgba(177, 177, 177, 0.133);
}
.custom-list-item.cli-checked {
  background: rgba(157, 197, 132, 0.2);
}
.custom-list-item .cli-label {
  margin-left: 5px;
}
.custom-list-item .cli-label.ccb-checked {
  color: #4EB064;
}
.custom-list-item .cli-icon {
  width: 14px;
  height: 14px;
  border-radius: 4px;
}
.custom-list-item .cli-icon .svg-icon {
  font-size: 9px;
}
.custom-list-item .cli-icon.cli-checked {
  background: #4EB064;
  color: white;
}
.custom-list-item .cli-icon:not(.cli-checked) {
  border: 0.5px solid #DFDFDF;
}
.search-box input {
  border: none;
  flex-grow: 1;
}
.search-box input:focus {
  outline: none;
}
.search-box.large input {
  font-size: 16px;
  line-height: 16px;
}
.search-box:not(.large) input {
  font-size: 12px;
  line-height: 12px;
}
.search-box .search-button {
  color: #9e9e9e;
  margin-right: 10px;
  margin-left: 10px;
}
.search-box .clear-button {
  color: #9e9e9e;
  margin-right: 10px;
}
.dd-sticky-header {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background: white;
}
.dd-sticky-header .dd-header {
  margin-top: 10px;
  margin-bottom: 3px;
}
.dd-sticky-header .dd-header .dd-header-title {
  padding: 0 1.14285714rem;
  color: rgba(0, 0, 0, 0.85);
  font-size: 0.78571429em;
  font-weight: 700;
  text-transform: uppercase;
}
.dd-sticky-header .dd-header .dd-all-button, .dd-sticky-header .dd-header .dd-none-button {
  color: #4EB064;
  margin-right: 10px;
  font-size: 11px;
  text-transform: uppercase;
}

.dd-items {
  margin-bottom: 10px;
  padding: 5px;
}
.dd-items .dd-item {
  padding-left: 10px;
  padding-right: 10px;
  height: 30px;
}
.dd-items .dd-item:hover {
  background: rgba(177, 177, 177, 0.133);
  border-radius: 8px;
}

.filter-search-box {
  height: 30px;
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  border: 1px solid #EDEDED;
  background-color: #ffffff;
  border-radius: 8px;
  color: #9e9e9e;
  font-size: 12px;
  line-height: 12px;
}
.dfi-container .dfi-selected-value {
  padding: 10px;
  text-align: center;
}
.dfi-container .dfi-enum-options {
  padding: 5px;
  border-right: 1px solid #DFDFDF;
}
.dfi-container table .suicr-dates-range-view-header tr:first-child {
  display: none;
}
.dfi-container table .suicr-dates-range-view-header tr:nth-child(2) th {
  color: #7b7b7b;
  padding-top: 8px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
}
.dfi-container table .suicr-dates-range-view-header tr:last-child th {
  font-size: 11px;
  color: #7b7b7b;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 0px;
  padding-right: 0px;
}
.dfi-container table tbody td {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}
.dfi-container .ui.table td.active {
  background-color: #4bc800 !important;
  color: white !important;
}
.toggle-checkbox {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  outline: none;
}
.toggle-checkbox input {
  visibility: hidden;
}
.toggle-checkbox .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}
.toggle-checkbox .slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}
.toggle-checkbox input:checked + .slider {
  background-color: #4EB064;
}
.toggle-checkbox input:focus + .slider {
  box-shadow: 0 0 1px #4EB064;
}
.toggle-checkbox input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  transform: translateX(16px);
}
.field-date-input .ui.input {
  width: 100%;
}
.not-form-container .not-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 31px;
  margin-bottom: 30px;
}
.not-form-container .option {
  margin-bottom: 20px;
}
.not-form-container .option .option-text {
  font-size: 16px;
}
.not-form-container .cancel-button {
  margin-right: 10px;
}
.information-modal .mc-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 31px;
}
.information-modal .mc-description {
  flex-grow: 1;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 26px;
}
.history-container .label {
  color: #9e9e9e;
  font-size: 12px;
  margin-top: 8px;
}
.history-container .info {
  font-size: 14px;
}
.history-container .title {
  font-weight: 600;
  margin-bottom: 10px;
}
.history-container .search-history {
  margin-left: -10px;
}
.dd-custom .search-box-container {
  background: #ffffff;
}
.dd-custom .dd-search-box {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}
.dd-custom .dd-trigger {
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 2px;
}
.dd-custom .dd-trigger:not(.is-open) {
  border: 1px solid #EDEDED;
}
.dd-custom .dd-trigger.is-open {
  border-top: 1px solid #EDEDED;
  border-left: 1px solid #EDEDED;
  border-right: 1px solid #EDEDED;
}
.dd-custom .dd-trigger input {
  padding: 10px;
  border: none;
  flex-grow: 1;
  padding: 11.5px 8.5px;
  font-size: 18px;
  line-height: 29px;
}
.dd-custom .dd-trigger input:focus {
  outline: none;
}
.dd-custom .dd-trigger .label-text {
  flex-grow: 1;
  color: #9e9e9e;
}
.dd-custom .dd-trigger .label-text:not(.small) {
  padding: 11.5px 8.5px;
  font-size: 18px;
  line-height: 29px;
}
.dd-custom .dd-trigger .label-text.small {
  padding: 10px;
}
.dd-custom .dd-trigger .ddt-icon {
  color: #9e9e9e;
  padding: 10px 10px 10px 0px;
}
.dd-custom .dd-trigger.selected:not(.format-selected) .label-text {
  color: #2E2D2E;
}
.dd-custom .dd-trigger.format-selected {
  background-color: #DE192B;
}
.dd-custom .dd-trigger.format-selected .label-text {
  color: #ffffff;
}
.dd-custom .dd-trigger.format-selected .ddt-icon {
  color: #ffffff;
}
.dd-custom .menu.transition.visible {
  border-left: 1px solid #EDEDED;
  border-right: 1px solid #EDEDED;
  border-bottom: 1px solid #EDEDED;
  border-top: none;
  border-radius: 0px;
  box-shadow: none;
}

.simple-list-item {
  font-size: 16px;
  line-height: 18px;
  padding: 8px 10px 8px 10px;
  color: #2E2D2E;
  cursor: pointer;
}
.simple-list-item:hover {
  background: rgba(177, 177, 177, 0.133);
}
.simple-list-item.selected {
  color: #ffffff;
  background: #DE192B;
}

.text-area-field {
  width: 100%;
  resize: none;
  height: 150px;
  padding: 14.5px 8.5px;
  font-size: 18px;
  line-height: 24px;
  color: #2E2D2E;
  border: 1px solid #DFDFDF;
}
.notifications-card {
  padding: 0px !important;
}
.notifications-card .notifications-header {
  padding: 15px;
}
.notifications-card .edit-button {
  margin-left: 8px;
}
.fd-container .fd-inner {
  flex-grow: 1;
}
.fd-container .fd-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 31px;
  margin-bottom: 10px;
}
.fd-container .label {
  color: #9e9e9e;
  font-size: 16px;
  line-height: 25px;
  margin-top: 20px;
}
.fd-container .info {
  font-size: 18px;
  line-height: 27px;
  font-weight: 600;
}
.orders-card {
  padding: 0px !important;
}
.orders-card .orders-header {
  padding: 15px;
}
.orders-card .orders-header .title {
  font-size: 18px;
  font-weight: 600;
}
.orders-card .edit-button {
  margin-left: 8px;
}
.settings-container .df-row-ac button {
  margin: 15px;
}
.stop-view {
  font-size: 16px;
  line-height: 25px;
}
.stop-view .stop-button {
  text-align: right;
}
.stop-view .label {
  color: #9e9e9e;
  font-size: 14px;
  line-height: 21px;
}
.stop-view .label:not(.without-margin) {
  margin-top: 20px;
}

.stop-top-margin {
  margin-top: 20px;
}
.transport-detail-card .label {
  color: #9e9e9e;
  font-size: 14px;
  line-height: 21px;
}
.transport-detail-card .label:not(.without-margin) {
  margin-top: 20px;
}
.transport-detail-card .info {
  font-size: 16px;
  line-height: 25px;
}
.transport-detail-card .single-price-calc {
  margin-right: 20px;
}
.transport-detail-card .dispute-button, .transport-detail-card .download-button {
  margin-left: 10px;
}
.transport-detail-card .status {
  padding: 7px 15px 7px 15px;
  border-radius: 100px;
}
.transport-detail-card .status.confirmed {
  color: white;
  background-color: #4EB064;
}
.transport-detail-card .status:not(.confirmed) {
  background-color: #DFDFDF;
}
.transport-detail-card .status .status-text {
  margin-left: 5px;
  line-height: 21px;
  white-space: nowrap;
}
.base-transports-card {
  padding: 0px !important;
}
.base-transports-card .transports-header {
  padding: 15px;
}
.base-transports-card .transports-header .title {
  font-size: 18px;
  font-weight: 600;
}
.base-transports-card .transports-header .comment {
  color: #9e9e9e;
}
.base-transports-card .transports-header .filter-button, .base-transports-card .transports-header .export-button {
  width: 80px;
}
.base-transports-card .transports-header .filter-button {
  margin-right: 10px;
}
.base-transports-card .footer .footer-page-info {
  color: #777777;
  font-size: 14px;
  line-height: 21px;
}
.base-transports-card .footer .previous-button {
  margin-right: 10px;
}
.base-transports-card .download-button {
  margin-left: 10px;
}
.container {
  height: 100%;
}
.container .body-wrapper {
  overflow: auto;
  width: 100%;
}

.container .side-nav {
  background-color: #EFF5FB;
  padding: 20px 10px 20px 15px;
}
.container .side-nav .c-label {
  margin-left: 5px;
  font-size: 10px;
}
.container .side-nav .nav-links {
  margin-top: 6px;
}
.container .side-nav .nav-links a {
  cursor: pointer;
  color: #626262;
  text-decoration: none;
}
.container .side-nav .nav-links a.active {
  font-weight: 600;
  color: #DE192B;
}
.container .side-nav .nav-links a.sub-nav-link {
  font-size: 16px;
  margin-top: 10px;
}
.container .side-nav .nav-links a.upper-nav-link {
  margin-top: 25px;
  font-size: 16px;
}
.container .side-nav .nav-links a.lower-nav-link {
  font-size: 14px;
  margin-top: 15px;
}
.container .side-nav .nav-links .company-name {
  font-weight: 600;
  font-size: 14px;
}
.container .side-nav .nav-links .sub-nav-link {
  margin-left: 10px;
}
.container .body-wrapper {
  background: #EFF5FB;
}
.container .body-wrapper .top-bar {
  background: #EFF5FB;
  padding: 20px 20px 5px 20px;
  z-index: 1;
}
.container .body-wrapper .top-bar .search-anything {
  flex-grow: 1;
  margin-right: 20px;
  max-width: 450px;
}
.container .body-wrapper .top-bar .user-text {
  font-size: 14px;
  line-height: 21px;
  margin-left: 15px;
}
.container .body-wrapper .body-content {
  padding: 20px 20px 20px 20px;
  height: 100%;
}
.dashboard-search-box {
  border: 1px solid #EDEDED;
  background-color: #ffffff;
  box-shadow: 2px 2px 8px 0 #DFDFDF;
  border-radius: 2px;
  padding: 10.5px 14.5px;
  color: #9e9e9e;
  font-size: 12px;
  line-height: 12px;
}
