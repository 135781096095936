@import '../../styles/variables';

.base-transports-card{
    padding: 0px !important;
    .transports-header{
        padding: 15px;
        .title{
            font-size: 18px;
            font-weight: 600;
        }
        .comment{
            color: $neutral-3-color;
        }
        .filter-button, .export-button{
            width: 80px;
        }
        .filter-button{
            margin-right: 10px;
        }
    }
    .footer{
        .footer-page-info{
            color: #777777;
            font-size: 14px;
            line-height: 21px;
        }
        .previous-button{
            margin-right: 10px;
        }
    }
    .download-button{
        margin-left: 10px;
    }
}