.information-modal{
    .mc-title{
        font-size: 24px;
        font-weight: 600;
        line-height: 31px;
    }
    .mc-description{
        flex-grow: 1;
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 26px;
    }
}