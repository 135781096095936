.not-form-container{
    .not-title{
        font-size: 24px;
        font-weight: 600;
        line-height: 31px;
        margin-bottom: 30px;
    }
    .option{
        margin-bottom: 20px;
        .option-text{
            font-size: 16px;
        }
    }
    .cancel-button{
        margin-right: 10px;
    }
}