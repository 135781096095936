@import '../../../styles/variables';

.filter-bar {
    padding-left: 15px;
    height: 50px;
    .filter-on-text{
        margin-right: 12px;
    }
    .filter-clear-button{
        margin-left: 20px;
        color: $neutral-4-color;
    }
}
