@import '../../styles/variables';

.fd-container{
    .fd-inner{
        flex-grow: 1;
    }
    .fd-title{
        font-size: 24px;
        font-weight: 600;
        line-height: 31px;
        margin-bottom: 10px;
    }
    .label{
        color: $neutral-3-color;
        font-size: 16px;
        line-height: 25px;
        margin-top: 20px;
    }
    .info{
        font-size: 18px;
        line-height: 27px;
        font-weight: 600;
    }
}