@import '../../../styles/variables';

.custom-list-item {
    font-size: 14px;
    cursor: pointer;
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 1px;
    margin-bottom: 1px;
    border-radius: 8px;

    &:hover {
        background: rgba(177, 177, 177, 0.133);
    }

    &.cli-checked {
        background: rgba(157, 197, 132, 0.2);
    }

    .cli-label {
        margin-left: 5px;

        &.ccb-checked {
            color: $success-color;
        }
    }

    .cli-icon {
        width: 14px;
        height: 14px;
        border-radius: 4px;

        .svg-icon {
            font-size: 9px;
        }

        &.cli-checked {
            background: $success-color;
            color: white;
        }

        &:not(.cli-checked) {
            border: 0.5px solid $neutral-4-color;
        }
    }
}