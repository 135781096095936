@import '../../styles/variables';

.stop-view {
    font-size: 16px;
    line-height: 25px;
    .stop-button{
        text-align: right;
    }
    .label{
        color: $neutral-3-color;
        font-size: 14px;
        line-height: 21px;
        &:not(.without-margin){
            margin-top: 20px;
        }
    }
}
.stop-top-margin{
    margin-top: 20px;
}